import React, { useEffect, useState, useRef } from "react";
import { over } from "stompjs";
import SockJS from "sockjs-client";
import { vasthuUrl } from "../component/Api";
import chatingbg from ".././images/chatingbg.jpg";
import { useTranslation } from "react-i18next";
import { Button, Modal, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Toast from "../component/Toast";

let stompClient = null;

const ChatRoom = () => {
  const token = window.sessionStorage.getItem("token");
  const userId = window.sessionStorage.getItem("userId");
  const role = window.sessionStorage.getItem("role");
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [chatData, setChatData] = useState("");
  const [privateChats, setPrivateChats] = useState([]);
  const [loading, setLoading] = useState(false);
  const [connected, setConnected] = useState(false);
  const [duration, setDuration] = useState();
  const [error, setError] = useState(null);
  const [userData, setUserData] = useState({
    username: "",
    receivername: "",
    connected: false,
    message: "",
  });

  const chatEndView = useRef(null);

  // ALERT MODAL
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [toastTrigger, setToastTrigger] = useState(0);

  const alert = (msg) => {
    setMessage(msg);
    setToastTrigger((prev) => prev + 1);
  };

  //Confirm Modal
  const [openModal, setOpenModal] = useState(false);

  const showModal = (i) => {
    setOpenModal(true);
  };

  const closeModal = () => {
    setOpenModal(false);
    window.history.pushState(null, "", window.location.href);
  };

  const handleBackPage = () => {
    hangUp();
  };

  useEffect(() => {
    const fetchChatDetails = async () => {
      try {
        setLoading(true);
        const endpoint =
          role === "ROLE_USER"
            ? `/initiateChat/getchatdetails/${userId}`
            : `/initiateChat/getchatdetailsforconsultant/${userId}`;
        const response = await vasthuUrl.get(endpoint, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setChatData(response.data);
        if (response.data) {
          const username = window.sessionStorage.getItem("authname");
          const receivername =
            role === "ROLE_USER"
              ? response.data.clientName
              : response.data.customerName;

          setUserData((prevState) => ({
            ...prevState,
            username,
            receivername,
          }));

          registerUser(username, receivername);
          setLoading(false);
        }
      } catch (err) {
        setError("Failed to fetch chat details.");
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    fetchChatDetails();
  }, [token, role, userId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await vasthuUrl
          .get(`/initiateChat/getChatCurrentSatus/${userId}`, {
            headers: { Authorization: `Bearer ${token}` },
          })
          .then((res) => {
            if (res.data.status !== "in-progress") {
              if (role == "ROLE_USER") {
                alert(t("CON.NO.CON"));
                setMessageType("error");
                setTimeout(() => {
                  navigate("/chathistory");
                }, 4000);
              } else {
                navigate("/");
              }
            }
          });
      } catch (err) {
        setError("Failed to fetch chat details.");
      } finally {
        setLoading(false);
      }
    };
    fetchData();

    const intervalId = setInterval(fetchData, 1000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (chatEndView.current) {
      chatEndView.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [privateChats]);

  useEffect(() => {
    if (connected) {
      if (role !== "ROLE_USER") {
        const response = vasthuUrl
          .put(
            `/initiateChat/updateChatStatus/${chatData.id}`,
            {
              chatFromId: chatData.chatFromId,
              chatToId: chatData.chatToId,
              status: "CONNECTED",
            },
            { params: { isConnected: true } }
          )
          .then((res) => {
            if (res.data) {
              setDuration(res.data.proposedChatDuration);
            }
          });
      }
    }
  }, [connected]);

  useEffect(() => {
    if (duration) {
      const durationInMilliseconds = duration * 1000;

      const timeoutId = setTimeout(() => {
        hangUp();
      }, durationInMilliseconds);

      return () => clearTimeout(timeoutId);
    }
  }, [duration]);

  // useEffect(() => {
  //   // Clear the history so the back button is disabled immediately
  //   window.history.pushState(null, "", window.location.href);
  //   window.history.replaceState(null, "", window.location.href);

  //   const preventBack = () => {
  //     window.history.pushState(null, "", window.location.href);
  //   };

  //   window.addEventListener("popstate", preventBack);

  //   return () => {
  //     window.removeEventListener("popstate", preventBack);
  //   };
  // }, []);

  useEffect(() => {
    const handleBackButton = (event) => {
      event.preventDefault();
      showModal();
      window.history.pushState(null, "", window.location.href);
    };

    window.history.pushState(null, "", window.location.href);
    window.addEventListener("popstate", handleBackButton);

    return () => {
      window.removeEventListener("popstate", handleBackButton);
    };
  }, []);

  const registerUser = (username, receivername) => {
    // console.log("Registering user with:", { username, receivername });
    connect(username);
  };

  const connect = (username) => {
    const chatUrl = `${process.env.REACT_APP_BAS_URL}ws`;
    const Sock = new SockJS(chatUrl);
    stompClient = over(Sock);
    // stompClient.connect({}, onConnected(username), onError);
    stompClient.connect({}, () => onConnected(username), onError);
    // console.log("username", username);
  };

  const onConnected = (username) => {
    setUserData((prevState) => ({ ...prevState, connected: true }));
    setConnected(true);
    stompClient.subscribe(`/user/${username}/private`, onPrivateMessage);
    userJoin(username);
  };

  const onError = (err) => {
    // console.error("Connection error:", err);
    setError("WebSocket connection failed.");
  };

  const userJoin = (username) => {
    const chatMessage = {
      senderName: username,
      status: "JOIN",
    };
    stompClient.send("/app/message", {}, JSON.stringify(chatMessage));
  };

  const onPrivateMessage = (payload) => {
    const payloadData = JSON.parse(payload.body);
    setPrivateChats((prevChats) => [...prevChats, payloadData]);
  };

  const sendPrivateMessage = () => {
    if (stompClient) {
      if (userData.message) {
        const chatMessage = {
          senderName: userData.username,
          receiverName: userData.receivername,
          message: userData.message,
          meetingCode: chatData.meetingCode,
          role: role,
        };

        setPrivateChats((prevChats) => [...prevChats, chatMessage]);

        stompClient.send(
          "/app/private-message",
          {},
          JSON.stringify(chatMessage)
        );

        setUserData((prevState) => ({ ...prevState, message: "" }));

        vasthuUrl
          .post(
            `/saveChats`,
            {
              senderName: userData.username,
              receiverName: userData.receivername,
              message: userData.message,
              meetingCode: chatData.meetingCode,
              role: role,
            },
            {
              params: { meetingCode: chatData.meetingCode },
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then((response) => {
            console.log("Message saved:", response.data);
          })
          .catch((error) => {
            console.error("Error saving chat:", error);
          });
      }
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserData((prevState) => ({ ...prevState, [name]: value }));
  };

  const hangUp = () => {
    console.log("hangup");
    vasthuUrl.put(`/initiateChat/disconnectChat/${chatData.id}`);
    if (role === "ROLE_USER") {
      navigate("/chathistory");
    } else {
      navigate("/");
    }
  };

  return (
    <div
      style={{
        padding: "20px",
        //  maxWidth: "600px",
        margin: "auto",
      }}
    >
      <Toast msg={message} type={messageType} trigger={toastTrigger} />
      {loading ? (
        <div className="text-center">
          <Spinner animation="border" variant="warning" />
        </div>
      ) : connected ? (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "82vh",
              padding: "0px 7px",
              backgroundImage: `url(${chatingbg})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              backgroundBlendMode: "overlay",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              // boxShadow:
              //   "0 2.8px 2.2px rgba(0, 0, 0, 0.034),0 6.7px 5.3px rgba(0, 0, 0, 0.048),0 12.5px 10px rgba(0, 0, 0, 0.06),0 22.3px 17.9px rgba(0, 0, 0, 0.072),0 41.8px 33.4px rgba(0, 0, 0, 0.086),0 100px 80px rgba(0, 0, 0, 0.12)",
              boxShadow: "0 10px 20px rgba(0, 0, 0, 0.3)",
            }}
          >
            <ul
              style={{
                listStyleType: "none",
                padding: "0",
                overflow: "auto",
              }}
            >
              {privateChats.length > 0 ? (
                privateChats.map((chat, index) => (
                  <li
                    key={index}
                    style={{
                      display: "flex",
                      justifyContent:
                        chat.senderName === userData.username
                          ? "flex-end"
                          : "flex-start",
                      margin: "10px 0",
                      overflowWrap: "anywhere",
                    }}
                  >
                    <div
                      style={{
                        // maxWidth: "70%",
                        padding: "10px",
                        backgroundColor:
                          chat.senderName === userData.username
                            ? "#d1e7dd"
                            : "#f8d7da",
                        borderRadius: "10px",
                      }}
                      className="chat_text_message"
                    >
                      {chat.senderName === userData.username ? (
                        <>
                          <span
                            dangerouslySetInnerHTML={{
                              __html: chat.message.replaceAll("\n", "<br />"),
                            }}
                          />
                          {/* {chat.message} */}
                          {/* <strong> : {userData.username}</strong> */}
                        </>
                      ) : (
                        <>
                          <strong>{chat.senderName} : </strong>
                          {/* {chat.message} */}
                          <span
                            dangerouslySetInnerHTML={{
                              __html: chat.message.replaceAll("\n", "<br />"),
                            }}
                          />
                        </>
                      )}
                    </div>
                  </li>
                ))
              ) : role == "ROLE_USER" ? (
                <p
                  style={{
                    textAlign: "center",
                    color: "red",
                    marginTop: "20px",
                  }}
                >
                  {t("USR.WAIT.FST.CHAT.MSG.ALT")}
                </p>
              ) : null}
              <div ref={chatEndView} />
            </ul>

            <div className="p-2 text-center d-flex flex-row justify-content-center">
              <textarea
                type="text"
                name="message"
                value={userData.message}
                onChange={handleInputChange}
                placeholder={t("USR.TYPE.MSG.PLHDER")}
                className="chat_input_feild"
                rows="1"
                disabled={role === "ROLE_USER" && privateChats.length === 0}
              />
              <Button
                onClick={sendPrivateMessage}
                disabled={role === "ROLE_USER" && privateChats.length === 0}
                className="chat_send_btn all_btn1"
              >
                {t("USR.SEND.BTN")}
              </Button>
            </div>
          </div>
          <div className="text-center">
            <Button className="my-3" variant="warning" onClick={() => hangUp()}>
              {t("CON.HANGUP.BTN")}
            </Button>
          </div>
        </>
      ) : (
        <div className="text-center">
          <Spinner animation="border" variant="warning" />
        </div>
      )}

      <Modal
        show={openModal}
        // backdrop="static"
        // keyboard={false}
        onHide={closeModal}
        className="all_border"
        // centered
      >
        <Modal.Header closeButton className="all_background">
          <h3>{t("CON.ALERT")}</h3>
        </Modal.Header>
        <Modal.Body>
          <h6>{t("CHT.END.TXT")}</h6>
        </Modal.Body>
        <Modal.Footer
          style={{
            display: "flex",
            justifyContent: "end",
            alignItems: "center",
            backgroundColor: "white",
          }}
        >
          <Button className="all_btn" onClick={handleBackPage}>
            {t("USR.VSH.HFTAB.MDL.CNF.BTN.CNF")}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ChatRoom;
