import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  Row,
  Form,
  Card,
  Modal,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { vasthuUrl } from "../component/Api";
import BillDeskPay from "../component/BillDeskPay";
import { UseAuth } from "../context/Auth";
import Toast from "../component/Toast";
import { useTranslation } from "react-i18next";

function WalletOffer(params) {
  console.log("params", params);
  const Navigate = useNavigate();
  const { t } = useTranslation();
  const lang = localStorage.getItem("i18nextLng");
  const token = window.sessionStorage.getItem("token");
  const role = window.sessionStorage.getItem("role");
  const crmUuid = params && params.crmUuid;
  const userId =
    role == "ROLE_CRM" ? crmUuid : window.sessionStorage.getItem("userId");
  const [savemoney, setSaveMoney] = useState([]);
  console.log("savemoney", savemoney);

  const [offers, setOffers] = useState([]);
  console.log("offers", offers);

  // ALERT MODAL
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const [toastTrigger, setToastTrigger] = useState(0);

  const alert = (msg) => {
    setMessage(msg);
    setToastTrigger((prev) => prev + 1);
  };

  //Payment Option
  const [paymentModal, setPaymentModal] = useState(false);
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);
  const [transactionId, setTransactionId] = useState(null);
  console.log("transactionId", transactionId);

  const ClosePaymentModal = () => setPaymentModal(false);

  const OpenPaymentModal = (actualPrice, offerId) => {
    setPaymentModal(true);
    setSelectedOffer(offerId);
    setSelectedPrice(actualPrice);
    console.log("offerId:", offerId, "actualPrice:", actualPrice);
  };

  const handleProceedPayment = () => {
    if (selectedOption === "Payment") {
      handleBuy(selectedPrice, selectedOffer);
    } else if (selectedOption === "NonPayment") {
      handleNonPayment(selectedPrice, selectedOffer);
    }
  };

  useEffect(() => {
    try {
      vasthuUrl
        .get("/getallactivewalletoffers", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setOffers(res.data);
          console.log("res.data", res.data);
        });
    } catch (e) {
      console.log("SERVER ERROR");
      setOffers([]);
    }

    try {
      vasthuUrl
        .get(`/getuserbalance/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setSaveMoney(res.data);
          console.log("service", res.data);
        });
    } catch (e) {
      console.log("service", e);
    }
  }, []);

  const BillDeskPayHandler = (txn) => {
    console.log(txn);
    vasthuUrl
      .post(
        "/user/bdpaymentresponseuiwallet",
        { txn: txn.txnResponse.transaction_response },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
      .then((res) => {
        console.log("txnres", res.data);
        // if (res.data.transaction_error_type == "success") {
        //   navigate("/customerpricing");
        // }

        if (res.data.transaction_error_type === "success") {
          console.log("Saving payment_response ....", txn);

          vasthuUrl
            .post("/user/bdpaymentresponsehandlerwallet", txn, {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            })
            .then((res1) => console.log("after txnsave", res1.data))
            .catch((err) => console.log("after txnsave try", err));
          alert(t("USR.PAYMENT.MSG"));
          setMessageType("success");
          const returnurl = window.sessionStorage.getItem("returnurl");
          setTimeout(() => {
            if (role == "ROLE_CRM") {
              Navigate("/accounts");
            } else {
              Navigate(returnurl);
            }
            window.sessionStorage.setItem("WalletAmount", 0);
          }, 2000);
          console.log("buying .. subsc");
        } else {
          console.log("Payment status changed to false");
        }
      });
  };

  const handleBuy = async (actualPrice, offerId) => {
    try {
      const response = await vasthuUrl
        .post(
          `/openapi/createwalletorderapi`,
          {
            userUuid: userId,
            walletofferId: offerId,
            price: actualPrice,
            currency: "356",
            returnUrl: "customerpricing",
            lng: lang,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          BillDeskPay(res.data, BillDeskPayHandler)
            .then((config) => {
              console.log("payout", config);
              window.loadBillDeskSdk(config);
              ClosePaymentModal();
            })
            .catch((err) => console.log("payment initialization fails", err));
        });
    } catch (err) {
      console.log(err);
      alert("Paytm server Error" + err);
      setMessageType("error");
    }
  };

  const handleNonPayment = async () => {
    if (!transactionId) {
      alert("Please enter a  transaction ID");
      console.log("Please enter a  transaction ID");
      setMessageType("error");
      return;
    }
    try {
      await vasthuUrl.post(
        "/saveUserBalance",
        {
          userUuid: userId,
          walletofferId: selectedOffer,
          txnId: transactionId,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      alert("Payment successful!");
      setMessageType("success");
      ClosePaymentModal();
      setTimeout(() => {
        Navigate("/accounts");
      }, 2000);
      console.log("Payment successful!");
    } catch (error) {
      console.error("Payment failed:", error);
      alert("Payment failed");
      setMessageType("error");
    }
  };

  return (
    <>
      <Toast msg={message} type={messageType} trigger={toastTrigger} />
      <Container>
        <div style={{ backgroundColor: "#ffc33b", marginTop: "5px" }}>
          <h3>
            <center>{t("USR.ADD.MONEY.WALT")}</center>
          </h3>
        </div>

        <Row
          style={{ display: "flex", alignItems: "center", marginTop: "15px" }}
        >
          <Col>
            <h2>
              <div>
                <b>
                  {t("USR.AVABLE.BALCE")}: ₹ {savemoney.balance}
                </b>
              </div>
            </h2>
          </Col>
        </Row>

        <h4>
          <u>{t("USR.POPLR.RECHARGE")}</u>
        </h4>

        <Row>
          {offers.map((offer, index) => (
            <Col xs={4} sm={4} md={3} lg={2} key={index}>
              <Card
                style={{
                  // width: "150px",
                  padding: "0",
                  borderRadius: "21px",
                  margin: "10px 0px",
                  border: "1px solid #ffc33b",
                  cursor: "pointer",
                  boxShadow:
                    "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 0px 2px 0 rgba(0, 0, 0, 0.19)",
                }}
                onClick={() => {
                  if (role == "ROLE_CRM") {
                    OpenPaymentModal(offer.actualPrice, offer.id);
                  } else {
                    handleBuy(offer.actualPrice, offer.id);
                  }
                }}
                className="card-hover"
              >
                <Card.Body>
                  <Card.Text>
                    <center>₹ {offer.actualPrice}</center>
                  </Card.Text>
                </Card.Body>
                <div
                  style={{
                    backgroundColor: index === 0 ? "#3ee46d" : "#ffc33b",
                    padding: "5px",
                    borderEndStartRadius: "20px",
                    borderEndEndRadius: "20px",
                  }}
                >
                  {/* <center>{offer.walletOffername}</center> */}
                  <center>
                    ₹&nbsp;{offer.extraAmount}&nbsp;{t("USR.RECHARGE.EXTRA")}
                  </center>
                </div>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>

      <Modal
        className="all_border"
        show={paymentModal}
        onHide={ClosePaymentModal}
      >
        <Modal.Header className="all_background" closeButton>
          <Modal.Title>Payment Option</Modal.Title>
        </Modal.Header>
        <Row>
          <Col xs={2} sm={2} md={4}></Col>
          <Col xs={8} sm={8} md={6}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "10px",
                padding: "8px",
              }}
            >
              <div>
                <input
                  type="radio"
                  name="PaymentOption"
                  value="Payment"
                  checked={selectedOption === "Payment"}
                  style={{ marginRight: "5px" }}
                  onChange={() => setSelectedOption("Payment")}
                />
                <label>Payment</label>
              </div>
              <div>
                <input
                  type="radio"
                  name="PaymentOption"
                  value="NonPayment"
                  checked={selectedOption === "NonPayment"}
                  style={{ marginRight: "5px" }}
                  onChange={() => setSelectedOption("NonPayment")}
                />
                <label>Non Payment</label>
              </div>

              {selectedOption == "NonPayment" ? (
                <Form>
                  <Form.Group className="mb-3" controlId="formBasicUser">
                    <Form.Label>Transaction Id</Form.Label>
                    <Form.Control
                      type="text"
                      className="form_feild"
                      placeholder="enter the transaction id ..."
                      onChange={(e) => setTransactionId(e.target.value)}
                      autoComplete="new-off"
                    />
                  </Form.Group>
                </Form>
              ) : null}
            </div>
          </Col>
          <Col xs={2} sm={2} md={3}></Col>
        </Row>
        <Modal.Footer>
          <Button
            className="all_btn"
            onClick={handleProceedPayment}
            disabled={
              (selectedOption == "NonPayment" && transactionId !== null) ||
              selectedOption == "Payment"
                ? false
                : true
            }
          >
            Next
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default WalletOffer;
